import React from "react";
import { motion } from "framer-motion";

const DescriptionServices = ({
  title,
  emphasis,
  description,
  image,
  conversionPercentage,
  conversionText,
}) => {
  return (
    <section className="py-10 sm:py-16 lg:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          className="grid gap-y-8 gap-x-16 xl:gap-x-24 items-center lg:grid-cols-2"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: {},
            visible: {
              transition: {
                staggerChildren: 0.3,
              },
            },
          }}
        >
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <h2 className="text-4xl lg:text-5xl font-bold text-gray-900 max-w-[500px]">
              {title} <span className="text-yellow-500">{emphasis}</span>
            </h2>
            <motion.p
              className="mt-6 text-lg text-gray-600 leading-relaxed"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.4 }}
            >
              {description}
            </motion.p>
          </motion.div>

          <motion.div
            className="hidden lg:block relative mb-12"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <div className="flex justify-center lg:justify-start">
              <div className="relative w-full sm:w-[564px] h-[646px] bg-gray-100 rounded-3xl border border-gray-200">
                <motion.img
                  loading="lazy"
                  className="w-full h-full rounded-3xl object-cover"
                  src={image}
                  alt="About Us"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.5 }}
                />
              </div>
            </div>

            <motion.div
              className="absolute -bottom-12 left-1/2 transform -translate-x-1/2 w-full max-w-xs sm:max-w-sm px-4 sm:px-0"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.6 }}
            >
              <div className="bg-white rounded-full shadow-lg overflow-hidden">
                <div className="px-10 py-6">
                  <div className="flex items-center">
                    <p className="text-3xl sm:text-4xl font-bold text-yellow-500 flex-shrink-0">
                      {conversionPercentage}
                    </p>
                    <p className="pl-6 text-sm sm:text-lg font-semibold">
                      {conversionText}
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default DescriptionServices;
