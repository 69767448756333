import React from "react";
import { motion } from "framer-motion";
import Step from "./ui/Step";

const HowItWorks = ({ title, subtitle, steps }) => {
  return (
    <div className="flex h-auto flex-col justify-center sm:mt-4 my-12">
      <div className="container mx-auto px-4 row">
        <motion.div
          className="text-center mb-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {title && (
            <motion.h2
              className="text-4xl lg:text-5xl font-bold"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.2 }}
            >
              {title}
            </motion.h2>
          )}
          {subtitle && (
            <motion.p
              className="text-2xl text-gray-600 m-3"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.4 }}
            >
              {subtitle}
            </motion.p>
          )}
        </motion.div>

        <motion.div
          className="flex flex-col items-center justify-between gap-10 lg:flex-row lg:gap-x-10"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: {},
            visible: {
              transition: {
                staggerChildren: 0.3,
              },
            },
          }}
        >
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
              >
                <Step
                  number={step.number}
                  title={step.title}
                  description={step.description}
                />
              </motion.div>
              {index < steps.length - 1 && (
                <motion.div
                  className="rotate-90 lg:rotate-0"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: 0.6 }}
                ></motion.div>
              )}
            </React.Fragment>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default HowItWorks;
