import React from "react";
import { motion } from "framer-motion";

const Features = ({ heading, subheading, description, features }) => {
  return (
    <section className="bg-gray-50">
      <div className="container mx-auto px-6 lg:px-8 max-w-[1200px]">
        <motion.div
          className="text-center mb-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {subheading && (
            <motion.span
              className="inline-block bg-[#ffc857] text-xs font-semibold rounded-full px-3 py-1"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.2 }}
            >
              {subheading}
            </motion.span>
          )}
          {heading && (
            <motion.h2
              className="text-4xl lg:text-5xl font-bold mt-3"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.4 }}
            >
              {heading}
            </motion.h2>
          )}
          {description && (
            <motion.p
              className="text-md max-w-xl mx-auto mt-3"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.6 }}
            >
              {description}
            </motion.p>
          )}
        </motion.div>

        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: {},
            visible: {
              transition: {
                staggerChildren: 0.2,
              },
            },
          }}
        >
          {features.map(({ icon: Icon, title, description }, index) => (
            <motion.div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 text-center group hover:shadow-xl transition-all duration-300"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <motion.div
                className="bg-[#ffc857] w-16 h-16 flex items-center justify-center rounded-full mb-4 mx-auto group-hover:scale-105 transition duration-300"
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <Icon className="text-2xl" />
              </motion.div>
              <h4 className="text-xl font-semibold text-gray-900 mb-2">
                {title}
              </h4>
              <p className="text-gray-500">{description}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Features;
