import React from "react";
import { motion } from "framer-motion";

const ServicesHero = ({
  backgroundImage,
  overlayOpacity = 0.5,
  title,
  emphasis,
  description,
}) => {
  return (
    <section
      className="relative bg-gray-900 bg-scroll sm:bg-fixed bg-cover bg-center text-white"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div
        className="absolute inset-0 bg-black"
        style={{ opacity: overlayOpacity }}
      ></div>
      <div className="relative py-16 px-4 mx-auto max-w-screen-xl lg:py-24 lg:px-6">
        <motion.div
          className="max-w-screen-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.h2
            className="text-4xl lg:text-5xl font-bold mb-3 text-white"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            {title}{" "}
            <span className="font-extrabold text-yellow-500">{emphasis}</span>
          </motion.h2>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            {description}
          </motion.p>
        </motion.div>
      </div>
    </section>
  );
};

export default ServicesHero;
