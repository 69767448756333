import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Button from './ui/Button';
import { Link } from 'react-router-dom';

const Hero = ({
  backgroundImage,
  title,
  subtitle,
  buttonText,
  buttonLink,
  secondaryLinkText,
  secondaryLink,
  overlayOpacity = 0.8,
}) => {
  return (
    <div
      className="relative bg-scroll sm:bg-fixed bg-center bg-cover"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div
        className="absolute inset-0 bg-black z-0"
        style={{ opacity: overlayOpacity }}
      ></div>
      <div className="relative px-6 pt-14 lg:px-8 z-10">
        <div className="mx-auto max-w-2xl py-32">
          <motion.div
            className="text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <motion.h1
              className="text-4xl font-bold text-white sm:text-6xl"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.2 }}
            >
              {title}
            </motion.h1>
            <motion.p
              className="mt-6 text-lg text-gray-300"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.4 }}
            >
              {subtitle}
            </motion.p>
            <motion.div
              className="mt-10 flex items-center justify-center gap-x-6"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, delay: 0.6 }}
            >
              {buttonText && buttonLink && (
                <Button label={buttonText} link={buttonLink} />
              )}
              {secondaryLink && secondaryLinkText && (
                <Link
                  to={secondaryLink}
                  className="text-sm font-semibold text-white"
                >
                  {secondaryLinkText} <FaArrowRight className="inline-block ml-1" />
                </Link>
              )}
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
