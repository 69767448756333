import React from "react";
import { motion } from "framer-motion";

const CTABanner = ({
  backgroundColor = "bg-yellow-500",
  title,
  subtitle,
  buttonText,
  buttonLink,
}) => {
  return (
    <section className={`${backgroundColor} py-12 px-6`}>
      <div className="max-w-7xl mx-auto text-center">
        <motion.h2
          className="text-5xl md:text-6xl font-extrabold mb-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          {title}
        </motion.h2>
        {subtitle && (
          <motion.p
            className="text-xl md:text-2xl mb-6 max-w-xl mx-auto"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            {subtitle}
          </motion.p>
        )}
        <motion.a
          href={buttonLink}
          className="inline-block px-10 py-4 text-lg font-semibold bg-white text-black rounded-full transform hover:scale-105 transition duration-300 ease-in-out"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.6 }}
          whileHover={{ scale: 1.1 }}
        >
          {buttonText}
        </motion.a>
      </div>
    </section>
  );
};

export default CTABanner;
