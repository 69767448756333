import React from "react";
import { motion } from "framer-motion";
import Button from "./ui/Button";
import Header from "./ui/Header";
import Para from "./ui/Para";
import { Link } from "react-router-dom";

const Description = ({
  title,
  subtitle,
  emphasis,
  paragraph,
  buttonLabel1,
  buttonLabel2,
  image1,
  image2,
}) => {
  return (
    <section className="bg-white py-12 lg:py-16">
      <div className="container mx-auto max-w-[1200px] grid grid-cols-1 lg:grid-cols-2 gap-12 items-center px-6 lg:px-12">
        <motion.div
          className="text-gray-700 dark:text-gray-300"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <Header title={title} subtitle={subtitle} emphasis={emphasis} />
          <Para para={paragraph} />
          <motion.div
            className="flex space-x-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            {buttonLabel1 && <Button label={buttonLabel1} />}
            {buttonLabel2 && (
              <Link
                to="/contact"
                className="text-gray-800 underline font-bold py-3 px-6"
              >
                {buttonLabel2}
              </Link>
            )}
          </motion.div>
        </motion.div>

        <motion.div
          className="grid grid-cols-2 gap-4"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: {},
            visible: {
              transition: {
                staggerChildren: 0.2,
              },
            },
          }}
        >
          {image1 && (
            <motion.img
              loading="lazy"
              className="w-full rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
              src={image1}
              alt="Office 1"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            />
          )}
          {image2 && (
            <motion.img
              loading="lazy"
              className="w-full rounded-lg shadow-lg mt-4 lg:mt-8 transform transition duration-300 hover:scale-105"
              src={image2}
              alt="Office 2"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.3 }}
            />
          )}
        </motion.div>
      </div>
    </section>
  );
};

export default Description;
