import React from "react";
import { motion } from "framer-motion";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const CallToAction = ({
  heading,
  subheading,
  buttonText,
  buttonLink,
  gradientFrom,
  gradientTo,
}) => {
  return (
    <section>
      <div className="container max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          className="rounded-xl flex flex-col lg:flex-row items-center justify-between p-8 lg:p-16 text-gray-900"
          style={{
            background: `linear-gradient(to right, ${gradientFrom}, ${gradientTo})`,
          }}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        >
          <motion.div
            className="text-center lg:text-left mb-6 lg:mb-0"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-4">{heading}</h2>
            <p className="text-base sm:text-lg">{subheading}</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            <Link
              to={buttonLink}
              className="flex items-center justify-center bg-white text-base sm:text-lg font-semibold py-3 px-6 rounded-full shadow-lg hover:text-gray-900 transition duration-300 ease-in-out transform hover:scale-105"
            >
              {buttonText} <FaArrowRight className="ml-2" />
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default CallToAction;
