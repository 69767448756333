import React from "react";
import { motion } from "framer-motion";

const DescriptionWithCards = ({
  title,
  emphasis,
  description,
  cards,
  image,
}) => {
  return (
    <section className="py-20 relative">
      <div className="max-w-7xl mx-auto px-4 md:px-5">
        <motion.div
          className="grid lg:grid-cols-2 gap-10 xl:gap-12"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: {},
            visible: {
              transition: {
                staggerChildren: 0.3,
              },
            },
          }}
        >
          <motion.div
            className="flex justify-center lg:justify-start order-2 lg:order-1"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <div className="relative w-full sm:w-[564px] h-[646px] bg-gray-100 rounded-3xl border border-gray-200">
              <motion.img
                loading="lazy"
                className="w-full h-full rounded-3xl object-cover"
                src={image}
                alt="About Us"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
              />
            </div>
          </motion.div>

          <motion.div
            className="flex flex-col justify-center gap-10 order-1 lg:order-2"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <motion.div
              className="flex flex-col gap-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              <div className="flex flex-col gap-4 items-center lg:items-start">
                <div className="flex flex-col gap-3 items-center lg:items-start">
                  <h1 className="text-4xl lg:text-5xl font-bold text-gray-900 max-w-[550px]">
                    {title}{" "}
                    <span className="text-yellow-500">{emphasis}</span>
                  </h1>
                  <p className="text-gray-500 text-base text-center lg:text-left">
                    {description}
                  </p>
                </div>
              </div>

              <motion.div
                className="grid md:grid-cols-2 gap-6"
                initial="hidden"
                animate="visible"
                variants={{
                  hidden: {},
                  visible: {
                    transition: {
                      staggerChildren: 0.2,
                    },
                  },
                }}
              >
                {cards.map((item, index) => (
                  <motion.div
                    key={index}
                    className="p-4 border border-gray-200 hover:border-gray-400 rounded-xl transition duration-700 ease-in-out"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <h4 className="text-gray-900 text-2xl font-bold">
                      {item.title}
                    </h4>
                    <p className="text-gray-500 text-base">{item.description}</p>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default DescriptionWithCards;
